import 'intersection-observer';


const options = {
    root: null, // 今回はビューポートをルート要素とする
    rootMargin: "0px 0px -40% 0px", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
};

const observer = new IntersectionObserver(doWhenIntersect, options);

const boxes = document.querySelectorAll("#observer");
//IE11対策
const boxesArr = Array.prototype.slice.call(boxes);
boxesArr.forEach(box => {
    observer.observe(box);
});

function doWhenIntersect(entries) {
    //IE11対策
    const entriesArr = Array.prototype.slice.call(entries);
    entriesArr.forEach(entry => {
        if (entry.isIntersecting) {
            document.getElementById('header').classList.add("show");
        }else{
            document.getElementById('header').classList.remove("show");
        }
    });
}


const options_ready = {
    root: null, // 今回はビューポートをルート要素とする
    rootMargin: "0px 0px -10% 0px", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
};

const observer_ready = new IntersectionObserver(doWhenIntersect_ready, options_ready);

const boxes_ready = document.querySelectorAll("#observer");
//IE11対策
const boxesArr_ready = Array.prototype.slice.call(boxes_ready);
boxesArr_ready.forEach(box => {
    observer_ready.observe(box);
});

function doWhenIntersect_ready(entries) {
    //IE11対策
    const entriesArr_ready = Array.prototype.slice.call(entries);
    entriesArr_ready.forEach(entry => {
        if (entry.isIntersecting) {
            document.getElementById('header').classList.add("ready");
        }else{
            document.getElementById('header').classList.remove("ready");
        }
    });
}







const options_car = {
    root: null, // 今回はビューポートをルート要素とする
    rootMargin: "0px 0px -30% 0px", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
};

const observer_car = new IntersectionObserver(doWhenIntersect_car, options_car);

const boxes_car = document.querySelectorAll("#car");
//IE11対策
const boxesArr_car = Array.prototype.slice.call(boxes_car);
boxesArr_car.forEach(box => {
    observer_car.observe(box);
});

function doWhenIntersect_car(entries) {
    //IE11対策
    const entriesArr_car = Array.prototype.slice.call(entries);
    entriesArr_car.forEach(entry => {
        if (entry.isIntersecting) {
            document.getElementById('car').classList.add("animate");
        }
    });
}
